<script setup lang="ts">
  import { useSlots } from 'vue'

  type Props = {
    readonly clickable?: boolean
    readonly sticky?: boolean
  }

  defineOptions({
    name: 'AlgListHeader',
  })

  const props = withDefaults(defineProps<Props>(), {
    clickable: false,
    sticky: true,
  })

  const emit = defineEmits<{
    (e: 'click', payload?: Event): void
  }>()

  const slots = useSlots()

  function handleClick(e: Event) {
    if (!props.clickable) {
      return
    }

    emit('click', e)
  }
</script>

<template>
  <div
    class="list-header-wrapper"
    :class="{
      clickable: props.clickable
    }"
    :style="{
      position: props.sticky ? 'sticky' : 'static',
      top: props.sticky ? '0' : 'auto',
    }"
    @click="handleClick"
  >
    <slot name="prepend" />

    <div class="list-header">
      <div class="list-header-title">
        <slot name="title" />
      </div>
      <div
        v-if="slots.subtitle"
        class="list-header-subtitle"
      >
        <slot name="subtitle" />
      </div>
    </div>
    <slot name="append" />
  </div>
</template>

<style lang="scss" scoped>
  .list-header-wrapper {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--alg-color-surface-background);
    gap: var(--alg-spacing-m);
    padding-inline: var(--alg-spacing-m);

    .list-header {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      padding: calc(var(--alg-spacing-s) + var(--alg-spacing-xs)) 0;
      gap: var(--alg-spacing-xs);
      line-height: var(--alg-font-line-height);

      .list-header-title {
        font-size: var(--alg-font-size-xs);
        font-weight: var(--alg-font-weight-bold);
        text-transform: uppercase;
      }

      .list-header-subtitle {
        font-size: var(--alg-font-size-xs);
      }
    }

    &.clickable {
      cursor: pointer;
      user-select: none;
    }
  }
</style>
