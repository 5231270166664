<script setup lang="ts">
  import { AlgIllustration, AlgModal } from '@algorh/ui'

  import { lang } from '@/plugins/langjs'

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const handleClose = () => {
    emit('close')
  }
</script>

<template>
  <AlgModal
    name="forbidden"
    size="m"
    :allow-cancel="false"
    :confirm-button-text="lang.trans('_.Continue')"
    @close="handleClose"
    @confirm="handleClose"
  >
    <template #title>
      {{ lang.trans('_.Unauthorized action') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgIllustration
          name="forbidden"
          :size="148"
        />
        <p class="subtitle">
          {{ lang.trans('_.You do not have permission to perform this action') }}
        </p>
        <p>{{ lang.trans('_.If the problem persists, please contact your administrator') }}</p>
      </div>
    </template>
  </AlgModal>
</template>

<style lang="scss" scoped>
  @import './AlertModal';
</style>
