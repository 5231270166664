import { UserPermissions } from '@/api/interfaces/User'
import AppLayout from '@/layouts/app/App.vue'

export const exportsRouter = [
  {
    path: 'exports',
    name: 'exports',
    component: () => import('@/sections/exports/Exports.vue'),
    meta: {
      redirectable: true,
      weight: 80,
      permissions: [UserPermissions.ACCESS_EXPORTS],
      layout: AppLayout,
      breadcrumb: 'exports.Data export',
    },
  },
]
