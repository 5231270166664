import { isRef, onBeforeUnmount, onMounted, Ref, unref, watch } from 'vue'

/**
 * Register using addEventListener on mounted, and removeEventListener automatically on unmounted.
 *
 * @param event
 * @param handler
 * @param target
 */
export function useEventListener(
  event: string,
  handler: (e: Event) => unknown,
  // the target could be reactive ref which adds flexibility
  target: Ref<EventTarget | HTMLElement | null> | EventTarget | HTMLElement,
) {
  if (!target) return
  // if its a reactive ref, use a watcher
  if (isRef(target)) {
    watch(target, (value, oldValue) => {
      oldValue?.removeEventListener(event, handler)
      value?.addEventListener(event, handler)
    })
  } else {
    // otherwise use the mounted hook
    onMounted(() => {
      target.addEventListener(event, handler)
    })
  }
  // clean it up
  onBeforeUnmount(() => {
    unref(target)?.removeEventListener(event, handler)
  })
}
