/**
 * Returns the rounded value of a number to a specified number of digits
 * @param value Number to round
 * @param digits Number of digits
 * @returns Rounded number
 */
export function round(value: number, digits = 0): number {
  const multiplier = Math.pow(10, digits)
  return Math.round(value * multiplier) / multiplier
}

/**
 * Returns the percentage of a number
 * @param count Number for which to calculate the percentage
 * @param total Total number
 * @param decimals Number of decimal places to round the result to (default: 0)
 * @returns Calculated percentage
 */
export function percentage(count = 0, total = 0, decimals = 0): number {
  if (count === 0 && total === 0)
    return 0

  if (total === 0)
    throw new Error('Total cannot be zero')

  const result = (100 * count) / total
  return round(result, decimals)
}

/**
 * Clamps a value within a range of values between a defined minimum bound and a maximum bound.
 * The function takes three parameters: a number value, a minimum value, and a maximum allowed value.
 * @param value The given number value
 * @param min The minimum value is the smallest number
 * @param max The maximum value is the largest number
 * @returns The clamped value
 */
export const clamp = (value: number, min: number, max: number) =>
  Math.max(Math.min(value, Math.max(min, max)), Math.min(min, max))
