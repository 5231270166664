<script setup lang="ts">
  import { DotSize } from '#/components'

  type Props = {
    label?: string
    color?: string
    size?: DotSize
  }

  defineOptions({
    name: 'AlgDot',
  })

  const props = withDefaults(defineProps<Props>(), {
    color: 'var(--alg-color-icon-unselected)',
    size: 'm',
  })
</script>

<template>
  <div :class="`dot-wrapper size-${props.size}`">
    <span
      class="dot"
      :style="{ backgroundColor: props.color }"
    />
    {{ props.label }}
  </div>
</template>

<style lang="scss" scoped>
  .dot-wrapper {
    display: flex;
    align-items: center;
    gap: var(--alg-spacing-xs);

    &.size-xs {
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-line-height-s);

      .dot {
        width: var(--alg-font-size-xxs);
        height: var(--alg-font-size-xxs);
      }
    }

    &.size-s {
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-line-height-s);

      .dot {
        width: var(--alg-font-size-s);
        height: var(--alg-font-size-s);
      }
    }

    &.size-m {
      font-size: var(--alg-font-size-m);
      line-height: var(--alg-line-height-m);

      .dot {
        width: var(--alg-font-size-m);
        height: var(--alg-font-size-m);
      }
    }

    &.size-l {
      font-size: var(--alg-font-size-l);
      line-height: var(--alg-line-height-l);

      .dot {
        width: var(--alg-font-size-l);
        height: var(--alg-font-size-l);
      }
    }

    .dot {
        border-radius: 50%;
    }
  }
</style>
