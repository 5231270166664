import { SubdivisionPreview, SubdivisionWithDates } from '@/api/interfaces/Subdivision'

import { DateTimeString, Nullable } from '@algorh/shared'

import { Company } from './Company'
import { CustomerAdviserPreview } from './CustomerAdviser'
import { Permission } from './Permission'
import { RolePreview } from './Role'
import { Tag } from './Tag'

interface UserRole {
  id: number
  label: string
}

enum UserPermissions {
  ACCEPT_OR_DENY_PENDING_ABSENCES = 'accept_or_deny_pending_absences',
  ACCEPT_OR_DENY_PENDING_ABSENCES_ON_CRITICAL_PERIODS = 'accept_or_deny_pending_absences_on_critical_periods',
  ACCESS_ABSENCES_ON_CRITICAL_PERIODS = 'access_absences_on_critical_periods',
  ACCESS_CALENDAR = 'access_calendar',
  ACCESS_CUSTOMER_ADVISERS = 'access_customer_advisers',
  ACCESS_HYPER_PERIODS = 'access_hyper_periods',
  ACCESS_OPERATIONAL_SUBDIVISION_STATISTICS = 'access_operational_subdivision_statistics',
  ACCESS_OTHER_USERS = 'access_other_users',
  ACCESS_PROJECT_STATISTICS = 'access_project_statistics',
  ACCESS_SCHEDULING = 'access_scheduling',
  ACCESS_SCHEDULING_PARAMETERS = 'access_scheduling_parameters',
  ACCESS_STATISTICS = 'access_statistics',
  ACCESS_TAGS = 'access_tags',
  ACCESS_VALIDATION_PROCESSES = 'access_validation_processes',
  ACCESS_WORK_CYCLES = 'access_work_cycles',
  ACCESS_INTERNAL_TASKS = 'access_internal_tasks',
  ARBITRATE_ABSENCES_ON_CRITICAL_PERIODS = 'arbitrate_absences_on_critical_periods',
  CREATE_ABSENCES_FOR_OTHER = 'create_absences_for_other',
  MANAGE_ACTIVITIES = 'manage_activities',
  MANAGE_CUSTOMER_ADVISERS = 'manage_customer_advisers',
  MANAGE_HYPER_PERIODS = 'manage_hyper_periods',
  MANAGE_PROJECTS = 'manage_projects',
  MANAGE_ROLES = 'manage_roles',
  MANAGE_RULES_AND_AMBITIONS = 'manage_rules_and_ambitions',
  MANAGE_SCHEDULING = 'manage_scheduling',
  MANAGE_SCHEDULING_PARAMETERS = 'manage_scheduling_parameters',
  MANAGE_SUBDIVISIONS = 'manage_subdivisions',
  MANAGE_TAGS = 'manage_tags',
  MANAGE_USERS = 'manage_users',
  MANAGE_VALIDATION_PROCESSES = 'manage_validation_processes',
  MANAGE_WORK_CYCLES = 'manage_work_cycles',
  SEE_ABSENCES = 'see_absences',
  SEE_GLOBAL_PLANNING = 'see_global_planning',
  MANAGE_INTERNAL_TASKS = 'manage_internal_tasks',
  MANAGE_MANUAL_SCHEDULING = 'manage_manual_scheduling',
  MANAGE_INDIVIDUAL_SCHEDULING = 'manage_individual_scheduling',
  IMPORT_LEAVE_BALANCES = 'import_leave_balances',
  ACCESS_EXPORTS = 'access_exports',
}

interface MeUser {
  id: number
  last_name: string
  first_name: string
  full_name: string
  email: string
  identifier: string
  roles: UserRole[]
  permissions: Record<UserPermissions, boolean>
}

interface SettingsUser {
  id: number
  last_name: string
  first_name: string
  identifier: string
  external_id: string
  email: string
  arrival_date: DateTimeString
  departure_date: Nullable<DateTimeString>
  roles: RolePreview[]
  permissions: Permission[]
  home_subdivisions: SubdivisionWithDates[]
  subordinate_subdivisions: SubdivisionPreview[]
  company: Nullable<Company>
  tags: Tag[]
  customer_adviser: Nullable<CustomerAdviserPreview>
  tutor: Nullable<string>
}

interface SettingsUserPreview {
  id: number
  last_name: string
  first_name: string
  full_name: string
  identifier: string
  external_id: string
  email: string
  roles: RolePreview[]
  home_subdivisions: SubdivisionWithDates[]
  company: Nullable<Company>
  deletable: boolean
  tags: Tag[]
  customer_adviser: Nullable<CustomerAdviserPreview>
}

interface SettingsUserWithAncestors {
  id: number
  last_name: string
  first_name: string
  full_name: string
  identifier: string
  external_id: string
  email: string
  roles: RolePreview[]
  home_subdivision: SubdivisionPreview
  ancestors: string[]
  deletable: boolean
  tags: Tag[]
  is_complete: boolean
}

interface SettingsUserAutocomplete {
  external_id: string
  last_name: Nullable<string>
  first_name: Nullable<string>
  email: Nullable<string>
}

interface EmployeeUser {
  id: number
  last_name: string
  first_name: string
  full_name: string
  identifier: string
  email: string
  roles: UserRole[]
  tags: Tag[]
  home_subdivision: SubdivisionPreview
  ancestors: string[]
}

type UserNotificationType = 'planning_update' | 'exchange' | 'activity_change'

interface UserNotificationContent {
  subject: string
  message: string
  type: UserNotificationType
}

interface UserNotification {
  id: string
  content: UserNotificationContent
  created_at: DateTimeString
  read_at: Nullable<DateTimeString>
}

export { UserPermissions }

export type {
  UserRole,
  MeUser,
  SettingsUser,
  SettingsUserPreview,
  SettingsUserWithAncestors,
  SettingsUserAutocomplete,
  EmployeeUser,
  UserNotification,
  UserNotificationContent,
  UserNotificationType,
}
