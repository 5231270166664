<script setup lang="ts">
  import { ref, onMounted, computed, watch } from 'vue'

  import { Nullable } from '@algorh/shared'

  import ChartCaption from '@/components/ChartCaption.vue'

  interface Props {
    readonly series?: number[]
    readonly colors?: string[]
    readonly labels?: string[]
    readonly size?: number
    readonly lineWidth?: number
    readonly margin?: number
    readonly direction?: 'vertical' | 'horizontal'
    readonly legend?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    series: () => [],
    colors: () => [],
    labels: () => [],
    size: 100,
    lineWidth: 12,
    margin: 0,
    direction: 'vertical',
    legend: true,
  })

  const canvas = ref<HTMLCanvasElement>()
  const ctx = ref<Nullable<CanvasRenderingContext2D>>()

  const legends = computed(() =>
    props.series.map((_, index) => ({
      color: props.colors[index],
      label: props.labels[index],
    })),
  )

  function draw(ctx: CanvasRenderingContext2D) {
    const colors = props.colors
    const radius = props.size / 2
    const lineWidth = props.lineWidth
    let lastAngle = -0.25 * 2 * Math.PI
    const total = props.series.reduce((a, b) => a + b, 0)

    ctx.imageSmoothingEnabled = true
    ctx.imageSmoothingQuality = 'high'
    ctx.lineWidth = lineWidth

    props.series.forEach((value, index) => {
      const angle = (total !== 0 ? value / total : 100) * 2 * Math.PI
      ctx.beginPath()
      ctx.strokeStyle = total !== 0 ? colors[index] : '#e4eaf4' // Default color
      ctx.arc(radius, radius, radius - lineWidth, lastAngle, lastAngle + angle)
      ctx.stroke()

      lastAngle += angle
    })
  }

  function init() {
    if (canvas.value) {
      const dpi = window.devicePixelRatio
      ctx.value = canvas.value.getContext('2d')

      canvas.value.width = props.size * dpi
      canvas.value.height = props.size * dpi
      canvas.value.style.width = `${props.size}px`
      canvas.value.style.height = `${props.size}px`

      if (!ctx.value) return
      ctx.value.scale(dpi, dpi)
      draw(ctx.value)
    }
  }

  watch(
    () => props.series,
    () => {
      if (ctx.value) {
        ctx.value.clearRect(0, 0, props.size, props.size)
        draw(ctx.value)
      }
    },
  )

  onMounted(() => init())
</script>

<template>
  <div
    class="canvas-donut"
    :class="props.direction"
  >
    <canvas
      ref="canvas"
      :style="{
        margin: props.direction === 'vertical' ? '0 auto' : '0'
      }"
    />
    <ChartCaption
      v-if="props.legend"
      :legends="legends"
      :direction="props.direction === 'vertical' ? 'horizontal' : 'vertical'"
    />
  </div>
</template>

<style lang="scss" scoped>
  .canvas-donut {
    display: flex;
    flex-direction: column;
    gap: var(--alg-spacing-s);

    &.horizontal {
      flex-flow: row wrap;
      align-items: center;
      margin: 0 auto;
    }
  }
</style>
