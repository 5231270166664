import type { NavigationGuardWithThis, RouteLocationNormalized, RouteRecordNormalized, Router } from 'vue-router'

import { UserPermissions } from '@/api/interfaces/User'
import { Nullable } from '@algorh/shared'
import { MeApiService } from '@/api/me.service'
import { queryClient } from '@/queryClient'

const DEFAULT_ROUTE = { name: 'landing' }
const canAccessRoute = (
  { meta }: RouteRecordNormalized | RouteLocationNormalized,
  permissions: Nullable<Record<UserPermissions, boolean>>,
) => !meta.permissions || meta.permissions.some((p) => permissions?.[p])

export function userLandingPage(
  router: Router,
  permissions: Nullable<Record<UserPermissions, boolean>>,
) {
  if (permissions === null || !Object.keys(permissions).length) {
    return DEFAULT_ROUTE
  }

  const canAccess = (route: RouteRecordNormalized) =>
    route.meta?.redirectable
    && canAccessRoute(route, permissions)

  const route = router
    .getRoutes()
    .filter(canAccess)
    .sort((a, b) => ((b.meta?.weight ?? 0) - (a.meta?.weight ?? 0)))[0]
  return route ?? DEFAULT_ROUTE
}

export async function navigationManager(
  router: Router,
  args: Parameters<NavigationGuardWithThis<undefined>>,
) {
  const authPages = [
    'login',
    'password-forgot',
    'password-init',
    'password-reset',
    'missing-user',
    'external-failure',
  ]

  const [to, , next] = args

  const me = await queryClient.fetchQuery({
    queryKey: ['me'],
    queryFn: MeApiService.getMe,
    staleTime: 24 * 60 * 60 * 1000, // 24H
  })

  if (!me?.data) {
    if (to.meta.unauthenticated) {
      next()
    } else {
      next({ name: 'login' })
    }
    return
  }

  if (!canAccessRoute(to, me?.data?.permissions ?? null)) {
    next({ name: 'forbidden', replace: true })
    return
  }

  if (me?.data && (to.name === 'home' || authPages.includes(to.name as string))) {
    next(userLandingPage(router, me?.data?.permissions ?? null))
    return
  }

  next()
}
