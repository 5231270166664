import { Nullable } from '@algorh/shared'
import { onMounted, onUnmounted } from 'vue'

function useInterval(
  callback: () => Promise<void>,
  {
    autostart = false,
    delay = 1000,
    immediate = true,
  }: { autostart?: boolean, delay?: number, immediate?: boolean } = {},
) {
  // eslint-disable-next-line no-undef
  let timer: Nullable<NodeJS.Timeout> = null
  let started = false

  async function start() {
    if (started) {
      return
    }
    started = true
    if (immediate) {
      await callback()
    }
    timer = setInterval(callback, delay)
  }

  function stop() {
    started = false
    if (timer !== null) {
      clearInterval(timer)
      timer = null
    }
  }

  onMounted(autostart ? start : () => undefined)
  onUnmounted(stop)

  return { start, stop }
}

export { useInterval }
