import { RouteLocation, RouterView } from 'vue-router'
import AppLayout from '@/layouts/app/App.vue'

import { DTF } from '@algorh/shared'

import { UserPermissions } from '@/api/interfaces/User'

import { dayjs } from '@/utils/dayjs'

const usersRouter = [
  {
    path: 'users',
    name: 'users',
    redirect: { name: 'user-list' },
    component: RouterView,
    meta: {
      layout: AppLayout,
      breadcrumb: 'Users',
    },
    children: [
      {
        path: '',
        name: 'user-list',
        redirect: { name: 'users-customer-advisers-list' },
        component: () => import('@/sections/users/Users.vue'),
        children: [
          {
            path: 'customer-advisers',
            redirect: { name: 'users-customer-advisers' },
            name: 'users-customer-advisers-list',
            component: RouterView,
            meta: {
              permissions: [UserPermissions.ACCESS_CUSTOMER_ADVISERS],
              breadcrumb: 'Advisers',
            },
            children: [
              {
                path: '',
                name: 'users-customer-advisers',
                component: () => import('@/sections/users/UsersCustomerAdvisers.vue'),
              },
              {
                path: ':id',
                name: 'users-customer-adviser',
                meta: {
                  breadcrumb: ':id',
                },
                redirect: (to: RouteLocation) => ({
                  name: 'users-customer-adviser-details',
                  params: {
                    id: to.params.id,
                    // Calendrier s'ouvre tjs sur le lundi de la semaine en cours
                    date: dayjs().startOf('week').format(DTF.DATE),
                  },
                }),
                children: [
                  {
                    path: ':date',
                    name: 'users-customer-adviser-details',
                    component: () => import('@/sections/users/user-details/UserDetails.vue'),
                  },
                  {
                    path: ':date/edit',
                    name: 'users-customer-adviser-edit',
                    component: () => import('@/sections/users/user-details/UserPlanningEdition.vue'),
                    meta: {
                      breadcrumb: 'Schedule manually',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'supervisors',
            name: 'users-supervisors',
            component: () => import('@/sections/users/UsersSupervisors.vue'),
            meta: {
              permissions: [UserPermissions.ACCESS_OTHER_USERS],
              breadcrumb: 'Supervisors',
            },
          },
        ],
      },

    ],
  },
]

export { usersRouter }
