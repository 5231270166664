import { RouteRecordRaw } from 'vue-router'

import { UserPermissions } from '@/api/interfaces/User'
import AppLayout from '@/layouts/app/App.vue'

const globalPlanningRouter: RouteRecordRaw[] = [
  {
    name: 'global-planning',
    path: 'global-planning/:view?/:year?/:month?/:week?/:day?',
    component: () => import('@/sections/planning/pages/GlobalPlanning.vue'),
    meta: {
      redirectable: true,
      weight: 1,
      permissions: [UserPermissions.SEE_GLOBAL_PLANNING],
      layout: AppLayout,
      breadcrumb: 'Global planning',
    },
  },
]

export { globalPlanningRouter }
