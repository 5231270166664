export function abbreviationOf(value: string) {
  if (value.match(/\b(\w)/g)) {
    return value
      .match(/\b(\w)/g)
      ?.join('')
      .substring(0, 2)
      .toUpperCase()
  } else {
    return ''
  }
}
