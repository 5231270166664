import { Ref } from 'vue'
import { useEventListener } from './useEventListener'

interface HotkeyOptions {
  // target element can be a reactive ref
  target: Ref<EventTarget | null> | EventTarget
  shiftKey: boolean
  ctrlKey: boolean
  metaKey: boolean
  exact: boolean
}

export function useHotkey(key: string, onKeyPressed: () => unknown, opts?: Partial<HotkeyOptions>) {
  // get the target element
  const target = opts?.target || window
  useEventListener(
    'keydown',
    (e) => {
      if (e instanceof KeyboardEvent) {
        const options = opts || {}
        if (e.key === key && matchesKeyScheme(options, e)) {
          e.preventDefault()
          onKeyPressed()
        }
      }
    },
    target,
  )
}
function matchesKeyScheme(
  opts: Pick<Partial<HotkeyOptions>, 'shiftKey' | 'ctrlKey' | 'exact' | 'metaKey'>,
  evt: KeyboardEvent,
) {
  const ctrlKey = opts.ctrlKey ?? false
  const shiftKey = opts.shiftKey ?? false
  const metaKey = opts.metaKey ?? false
  if (opts.exact) {
    return ctrlKey === evt.ctrlKey && shiftKey === evt.shiftKey
  }
  const satisfiedKeys: boolean[] = []
  satisfiedKeys.push(ctrlKey === evt.ctrlKey)
  satisfiedKeys.push(shiftKey === evt.shiftKey)
  satisfiedKeys.push(metaKey === evt.metaKey)
  return satisfiedKeys.every((key) => key)
}
