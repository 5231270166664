import { ProjectActivityLite } from '@algorh/shared'

import { CommonConfig } from '@/api/interfaces/Common'

import { fetchJson } from '@/utils/fetch'

import { RolePreview } from './interfaces/Role'
import { SubdivisionPreview, SubdivisionsNamesTree } from './interfaces/Subdivision'

const BASE_URI = '/api/common'

const CommonApiService = {
  getCommonConfig() {
    return fetchJson<CommonConfig>('GET', `${BASE_URI}/config`)
  },
  getProjectActivities() {
    return fetchJson<ProjectActivityLite[]>('GET', `${BASE_URI}/project-activities`)
  },
  getSubdivisions() {
    return fetchJson<SubdivisionPreview[]>('GET', `${BASE_URI}/operational-subdivisions`)
  },
  getSubdivisionsNamesTree() {
    return fetchJson<SubdivisionsNamesTree[]>('GET', `${BASE_URI}/operational-subdivisions/tree`)
  },
  getEffectiveSubdivisions() {
    return fetchJson<SubdivisionPreview[]>('GET', `${BASE_URI}/operational-subdivisions/effective`)
  },
  getRoles() {
    return fetchJson<RolePreview[]>('GET', `${BASE_URI}/roles`)
  },
}

export { CommonApiService }
