import { Activity } from '@/api/interfaces/employees/Activity'
import { ActivityFamily } from '@/api/interfaces/employees/ActivityFamily'

import { fetchJson } from '@/utils/fetch'

import { EmployeeConfig } from '../interfaces/Employee'

const BASE_URI = '/api/employees'

const EmployeesApiService = {
  getConfig() {
    return fetchJson<EmployeeConfig>('GET', `${BASE_URI}/config`)
  },
  getActivities() {
    return fetchJson<Activity[]>('GET', `${BASE_URI}/activities`)
  },
  getActivityFamilies() {
    return fetchJson<ActivityFamily[], null>('GET', `${BASE_URI}/activity-families`)
  },
}

export { EmployeesApiService }
