<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, onBeforeMount, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

  import { useHotkey } from '@algorh/shared'

  import { AlgButton, AlgIcon } from '#/components'
  import { ButtonVariant } from '#/types'

  import { ModalSize } from './size'

  type Props = {
    readonly allowCancel?: boolean
    readonly allowClose?: boolean
    readonly ariaDescribedBy?: string
    readonly ariaLabelledBy?: string
    readonly borderedFooter?: boolean
    readonly borderedHeader?: boolean
    readonly cancelButtonDisabled?: boolean
    readonly cancelButtonDisplayed?: boolean
    readonly cancelButtonText?: string
    readonly cancelButtonType?: ButtonVariant
    readonly closeButtonDisabled?: boolean
    readonly confirmButtonDisabled?: boolean
    readonly confirmButtonLoading?: boolean
    readonly confirmButtonText?: string
    readonly confirmButtonType?: ButtonVariant
    readonly exportButtonDisplayed?: boolean
    readonly footer?: boolean
    readonly formId?: string
    readonly height?: number
    readonly name: string
    readonly size?: ModalSize
    readonly width?: number
  }

  const props = withDefaults(defineProps<Props>(), {
    allowCancel: true,
    allowClose: true,
    ariaDescribedBy: 'modal-content',
    ariaLabelledBy: 'modal-header-title',
    borderedFooter: false,
    borderedHeader: true,
    cancelButtonDisabled: false,
    cancelButtonDisplayed: true,
    cancelButtonType: 'secondary',
    closeButtonDisabled: false,
    confirmButtonDisabled: false,
    confirmButtonLoading: false,
    confirmButtonType: 'primary',
    exportButtonDisplayed: false,
    footer: true,
    formId: '',
    width: 0,
    height: 0,
  })

  const emit = defineEmits<{
    (e: 'cancel'): void
    (e: 'confirm'): void
    (e: 'close'): void
    (e: 'export'): void
  }>()

  // Refs
  const visible = ref(false)

  const backdrop = ref<HTMLDivElement>()

  const isFullScreen = ref(false)

  const target = ref<HTMLDivElement>()

  // Composables
  const { t } = useI18n()

  useFocusTrap(target, { immediate: true })

  useHotkey('Escape', () => handleClose())

  // Computed
  const customStyle = computed(() => {
    const hasStyle = props.width > 0 || props.height > 0
    if (!hasStyle) {
      return {}
    }
    const width = props.width ? `${props.width}px` : 'inherit'
    const height = props.height ? `${props.height}px` : 'inherit'
    return {
      width: isFullScreen.value ? '100%' : width,
      height: isFullScreen.value ? '100%' : height,
      maxHeight: isFullScreen.value ? '100%' : 'inherit',
      borderRadius: isFullScreen.value ? '0' : 'var(--alg-effect-radius-l)',
    }
  })

  // Methods
  function handleResize() {
    if (!props.width || !backdrop.value) {
      return
    }

    isFullScreen.value = props.width > backdrop.value.offsetWidth
  }

  function handleCancel() {
    emit('cancel')
  }

  function handleConfirm() {
    if (props.confirmButtonLoading) {
      return
    }

    emit('confirm')
  }

  function handleClose() {
    if (!props.allowClose || props.confirmButtonLoading) {
      return
    }

    visible.value = false

    setTimeout(() => {
      emit('close')
    }, 50)
  }

  function handleExport() {
    emit('export')
  }

  // Hooks
  onBeforeMount(() => {
    const targetElement = document.getElementById('modals')
    if (!targetElement) {
      throw Error('Element with id "modals" is missing')
    }
  })

  onMounted(() => {
    visible.value = true

    if (props.width && backdrop.value) {
      isFullScreen.value = props.width > backdrop.value.offsetWidth
    }

    document.body.style.overflow = 'hidden'
    window.addEventListener('resize', handleResize)
  })

  onBeforeUnmount(() => {
    document.body.style.overflow = 'unset'
    window.removeEventListener('resize', handleResize)
  })
</script>

<template>
  <Teleport to="#modals">
    <div class="modal-wrapper">
      <div
        ref="backdrop"
        class="modal-backdrop"
        :class="{ visible }"
        @mousedown="handleClose"
      />
      <div
        ref="target"
        class="modal"
        :class="[
          `${props.name}-modal`,
          `size-${props.size}`,
          {
            'with-footer': props.footer
          }
        ]"
        :style="customStyle"
        role="dialog"
        :aria-describedby="`${props.name}-${props.ariaDescribedBy}`"
        :aria-labelledby="`${props.name}-${props.ariaLabelledBy}`"
      >
        <div
          class="modal-header-wrapper"
          :class="{ bordered: props.borderedHeader }"
        >
          <div class="modal-header">
            <div
              :id="`${props.name}-modal-header-title`"
              class="modal-header-title"
            >
              <slot name="title" />
            </div>
            <button
              v-if="props.allowClose"
              class="modal-header-close-button"
              type="button"
              :disabled="props.closeButtonDisabled"
              :title="t('common.Close')"
              @click="handleClose"
            >
              <AlgIcon
                name="cancel"
                size="m"
              />
            </button>
          </div>
          <div class="modal-subheader">
            <slot name="subheader" />
          </div>
        </div>
        <div
          :id="`${props.name}-modal-content`"
          class="modal-content"
        >
          <slot name="content" />
        </div>
        <div
          v-if="props.footer"
          class="modal-footer"
          :class="{ bordered: props.borderedFooter }"
        >
          <slot name="footer">
            <template v-if="props.allowCancel">
              <AlgButton
                v-if="props.exportButtonDisplayed"
                :label="t('common.Export')"
                variant="secondary"
                icon-end="upload"
                @click="handleExport"
              />
              <div class="centered-button">
                <AlgButton
                  v-if="props.cancelButtonDisplayed"
                  :label="props.cancelButtonText ?? t('common.Cancel')"
                  :variant="props.cancelButtonType"
                  :disabled="props.cancelButtonDisabled"
                  @click="handleCancel"
                />
                <AlgButton
                  :type="props.formId ? 'submit' : 'button'"
                  :form="props.formId"
                  :label="props.confirmButtonText ?? t('common.Validate')"
                  :variant="props.confirmButtonType"
                  :disabled="props.confirmButtonDisabled"
                  :loading="props.confirmButtonLoading"
                  @click="handleConfirm"
                />
              </div>
            </template>
            <template v-else>
              <AlgButton
                :label="props.confirmButtonText ?? t('common.Close')"
                :variant="props.confirmButtonType"
                :disabled="props.confirmButtonDisabled"
                :loading="props.confirmButtonLoading"
                @click="handleConfirm"
              />
            </template>
          </slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @mixin modal-shape {
    border-radius: var(--alg-effect-radius-l);
    box-shadow: var(--alg-effect-shadow-m);
  }

  .modal-wrapper {
    position: fixed;
    z-index: 120;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;

    .modal-backdrop {
      position: fixed;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fade-in 50ms ease-in-out;
      background-color: rgb(0 0 0 / 50%);
      inset: 0;
      opacity: 0;
      transition: opacity 50ms ease-in-out;

      &.visible {
        opacity: 1;
      }
    }

    .modal {
      z-index: 100;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      background-color: var(--alg-color-surface-primary);

      --modal-header-height: 75px;
      --modal-footer-height: 75px;

      &.size-auto {
        @include modal-shape;

        width: auto;
        height: auto;
      }

      /* Classic modals */
      &.size-xs {
        @media screen and (min-width: $w-small-s) {
          @include modal-shape;

          width: $w-small-s;
          height: 280px;
        }
      }

      &.size-s {
        @media screen and (min-width: $w-small-s) {
          @include modal-shape;

          width: $w-small-s;
          height: 350px;
        }
      }

      &.size-m {
        @media screen and (min-width: $w-small-s) {
          @include modal-shape;

          width: $w-small-s;
          height: 500px;
        }
      }

      &.size-l {
        @media screen and (min-width: $w-small-s) {
          @include modal-shape;

          width: $w-small-s;
          height: 620px;
        }
      }

      &.size-xl {
        @media screen and (min-width: $w-medium-s) {
          @include modal-shape;

          width: $w-medium-s;
          height: 90%;
          min-height: 620px;
        }
      }

      /* Double column modals */

      &.size-double-xs {
        @media screen and (min-width: $w-medium-l) {
          @include modal-shape;

          width: $w-medium-l;
          height: 280px;
        }
      }

      &.size-double-s {
        @media screen and (min-width: $w-medium-l) {
          @include modal-shape;

          width: $w-medium-l;
          height: 55%;
          min-height: 520px;
        }
      }

      &.size-double-m {
        @media screen and (min-width: $w-medium-l) {
          @include modal-shape;

          width: $w-medium-l;
          height: 65%;
          min-height: 620px;
        }
      }

      &.size-double-l {
        @media screen and (min-width: $w-medium-l) {
          @include modal-shape;

          width: $w-medium-l;
          height: 75%;
          min-height: 620px;
        }
      }

      &.size-double-xl {
        @media screen and (min-width: $w-medium-l) {
          @include modal-shape;

          width: $w-medium-l;
          height: 90%;
          min-height: 620px;
        }
      }

      /* Specific modals */
      &.size-full {
        @media screen and (min-width: $w-large-l) {
          @include modal-shape;

          width: $w-large-l;
          height: 700px;
        }
      }

      .modal-header-wrapper {
        display: flex;
        flex-direction: column;

        &.bordered {
          border-bottom: 1px solid var(--alg-color-surface-border);
        }

        .modal-header {
          position: relative;
          display: flex;
          height: var(--modal-header-height);
          box-sizing: border-box;
          align-items: center;
          justify-content: space-between;
          padding: 25px 50px 25px 25px;

          .modal-header-title {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            color: var(--alg-color-text-primary);
            font-size: var(--alg-font-size-xl);
            font-weight: var(--alg-font-weight-bold);
            text-align: left;
          }

          .modal-header-close-button {
            position: absolute;
            top: 50%;
            right: 25px;
            padding: 0;
            border-radius: 50%;
            color: var(--alg-color-text-secondary);
            transform: translateY(-50%);
            transition: color 150ms ease-in-out;

            &:hover {
              color: var(--alg-color-text-primary);
            }

            &[disabled] {
              color: var(--alg-color-text-light);
              cursor: not-allowed;
            }
          }
        }

        .modal-subheader {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 25px;
        }
      }

      .modal-content {
        display: flex;
        max-height: calc(100% - var(--modal-header-height));
        flex: 1 1 auto;
        padding: var(--alg-spacing-m);
        color: var(--alg-color-text-primary);
        overflow-y: auto;

        @media screen and (min-width: $w-medium-s) {
          padding: var(--alg-spacing-l);
        }
      }

      .modal-footer {
        display: flex;
        height: var(--modal-footer-height);
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        padding: 15px 25px 20px;
        gap: 10px;

        .centered-button {
          display: flex;
          margin: 0 auto;
          gap: 10px;
        }

        &.bordered {
          border-top: 1px solid var(--alg-color-surface-border);
        }
      }

      &.with-footer {
        max-height: calc(100% - var(--modal-header-height) - var(--modal-footer-height));

        @media screen and (width <= 500px) {
          height: 100vh;
          max-height: 100vh;
        }

        .modal-content {
          padding: 25px 25px 10px;
        }
      }
    }
  }
</style>
