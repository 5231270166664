import AppLayout from '@/layouts/app/App.vue'

const notificationsRouter = [
  {
    name: 'notifications',
    path: 'notifications',
    meta: {
      layout: AppLayout,
      breadcrumb: 'common.Notifications',
    },
    component: () => import('@/sections/notifications/Notifications.vue'),
  },
]

export { notificationsRouter }
