import { defineStore } from 'pinia'

import { ref } from 'vue'

import { AlertVariant } from '@algorh/ui'

interface Alert {
  id: string | number
  variant: AlertVariant
  title?: string
  message: string
  autoHide?: boolean
  closable?: boolean
  onClose?: () => void
}

export const useAlertsStore = defineStore('alerts', () => {
  const alerts = ref<Alert[]>([])

  function setAlert(alert: Omit<Alert, 'id'>, customId?: string | number) {
    alerts.value = [{ id: customId ?? new Date().valueOf(), ...alert }, ...alerts.value]
  }

  function removeAlert(id: string | number) {
    alerts.value = alerts.value.filter((alert) => alert.id.toString() != id.toString())
  }

  function clearAlerts() {
    alerts.value = []
  }

  return {
    alerts,
    setAlert,
    removeAlert,
    clearAlerts,
  }
})
