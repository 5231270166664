export const ILLUSTRATION_CALENDARS_NAMES = [
  'congrats',
  'contact-us',
  'deploying',
  'error',
  'forbidden',
  'incompatible-browser',
  'not-found',
  'waiting',
] as const

export const ILLUSTRATION_COLORED_NAMES = [
  'alarm-clock',
  'balance',
  'closed',
  'faders',
  'lightbulb',
  'lock',
  'menu',
  'run',
  'support',
  'tasklist',
] as const

export const ILLUSTRATION_CUSTOM_NAMES = [
  'rate',
  'upload',
]

export const ILLUSTRATION_NAMES = [
  ...ILLUSTRATION_COLORED_NAMES,
  ...ILLUSTRATION_CALENDARS_NAMES,
  ...ILLUSTRATION_CUSTOM_NAMES,
] as const
