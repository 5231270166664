import { RouteRecordRaw, RouterView } from 'vue-router'

import { UserPermissions } from '@/api/interfaces/User'
import AppLayout from '@/layouts/app/App.vue'

const projectsRouter: RouteRecordRaw[] = [
  {
    name: 'projects',
    path: 'projects',
    redirect: { name: 'project-list' },
    meta: {
      permissions: [UserPermissions.MANAGE_PROJECTS],
      layout: AppLayout,
      breadcrumb: 'Projects',
    },
    component: RouterView,
    children: [
      {
        name: 'project-list',
        path: '',
        component: () => import('@/sections/projects/Projects.vue'),
      },
      {
        name: 'project',
        path: ':projectId',
        redirect: { name: 'project-activities' },
        meta: {
          permissions: [UserPermissions.MANAGE_PROJECTS],
          layout: AppLayout,
          breadcrumb: ':projectId',
        },
        component: () => import('@/sections/projects/Project.vue'),
        children: [
          {
            name: 'project-activities',
            path: 'activities',
            meta: {
              permissions: [UserPermissions.MANAGE_PROJECTS],
              breadcrumb: 'Activities',
            },
            component: () => import('@/sections/projects/activities/ProjectActivities.vue'),
          },
          {
            name: 'project-rules',
            path: 'rules',
            meta: {
              breadcrumb: 'Collective rules',
            },
            component: () => import('@/sections/projects/rules-ambitions/ProjectRules.vue'),
          },
          {
            name: 'project-ambitions',
            path: 'ambitions',
            meta: {
              breadcrumb: 'Ambitions',
            },
            component: () => import('@/sections/projects/rules-ambitions/ProjectAmbitions.vue'),
          },
        ],
      },
    ],
  },
]

export { projectsRouter }
