import { ref } from 'vue'

import { useEventListener } from '.'

export function useMatchMedia(query: string) {
  const mediaQueryList = ref<MediaQueryList>(matchMedia(query))
  const matches = ref(mediaQueryList.value.matches)

  const process = (e: MediaQueryListEvent) => {
    matches.value = e.matches
  }

  // @ts-expect-error C'est claqué !
  useEventListener('change', process, window)

  return {
    mediaQueryList,
    matches,
  }
}
