<script setup lang="ts">
  import { AlgIcon } from '@algorh/ui'

  type Props = {
    readonly open?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    open: false,
  })
</script>

<template>
  <details
    :open="props.open"
    class="details"
  >
    <summary class="summary">
      <div class="summary-content">
        <slot name="summary" />
        <span class="arrow">
          <AlgIcon
            name="expand-more"
            color="var(--alg-color-icon-primary)"
            size="s"
          />
        </span>
      </div>
    </summary>
    <div class="details-content">
      <slot name="details" />
    </div>
  </details>
</template>

<style lang="scss" scoped>
.details {
  padding: 0;
  border: none;

  .summary {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    border: none;
    cursor: pointer;

    .summary-content {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .arrow {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] {
    .summary {
      .arrow {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>
