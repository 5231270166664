import { Company } from '@/api/interfaces/Company'
import { CustomerAdviserType } from '@/api/interfaces/CustomerAdviser'

import { SettingsConfig } from '@/interfaces'

import { fetchJson } from '@/utils/fetch'
import { ProjectActivityLite } from '@algorh/shared'

const BASE_URI = '/api/settings'

const SettingsApiService = {
  getSettingsConfig() {
    return fetchJson<SettingsConfig>('GET', `${BASE_URI}/config`)
  },
  getProjectActivities() {
    return fetchJson<ProjectActivityLite[]>('GET', `${BASE_URI}/project-activities`)
  },
  getCustomerAdviserTypesPreviews() {
    return fetchJson<CustomerAdviserType[]>('GET', `${BASE_URI}/customer-adviser-types/previews`)
  },
  getCompanies() {
    return fetchJson<Company[]>('GET', `${BASE_URI}/companies`)
  },
}

export { SettingsApiService }
