import { DateString } from '@algorh/shared'

import { fetchJson } from '@/utils/fetch'

import {
  CalendarScheduleStatistic,
  CalendarCurrentSchedule,
} from '../interfaces/Calendar'

import { ScheduleSatisfaction } from '../interfaces/Schedule'

import { Satisfaction } from '../interfaces/Planning'

import { GetSchedulesDto, GetScheduleSatisfactionsDto } from './customerAdvisers.service.type'

const BASE_URI = '/api/calendar/customer-advisers'

const CalendarCustomerAdvisersApiService = {
  getSchedules(params: GetSchedulesDto) {
    return fetchJson<CalendarScheduleStatistic[], null, GetSchedulesDto>(
      'GET',
      `${BASE_URI}/me/schedules`,
      null,
      params,
    )
  },
  getCurrentSchedule() {
    return fetchJson<CalendarCurrentSchedule>('GET', `${BASE_URI}/me/schedules/current`)
  },
  getScheduleByDate(date: DateString) {
    return fetchJson<CalendarCurrentSchedule, null, { date: DateString }>(
      'GET',
      `${BASE_URI}/me/schedules/at`,
      null,
      { date },
    )
  },
  getGlobalSatisfaction() {
    return fetchJson<Satisfaction>('GET', `${BASE_URI}/me/statistics`)
  },
  getScheduleSatisfactions(dto: GetScheduleSatisfactionsDto) {
    return fetchJson<ScheduleSatisfaction[], null, GetScheduleSatisfactionsDto>(
      'GET',
      `${BASE_URI}/me/schedules/satisfactions`,
      null,
      dto,
    )
  },
}

export { CalendarCustomerAdvisersApiService }
