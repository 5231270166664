import { DateString, DateTimeString, ShortTimeString, TimeString } from '../types'

/**
 * Date Time Format
 * @see https://day.js.org/docs/en/display/format
 */
export const DTF = {
  DATE: 'YYYY-MM-DD' as DateString,
  DATE_FULL: 'DD MMMM YYYY',
  DATE_MONTH_YEAR: 'MMMM YYYY',
  DATE_SHORT: 'YY-MM-DD',

  DATETIME: 'YYYY-MM-DD HH:mm:ss' as DateTimeString,
  DATETIME_SHORT: 'YYYY-MM-DD HH:mm',

  LITERAL_DATE: 'dddd DD MMMM',
  LITERAL_DATE_FULL: 'dddd DD MMMM YYYY',
  LITERAL_DATETIME_SHORT: 'dddd DD MMMM - HH:mm',
  LITERAL_DAY_MONTH: 'DD MMMM',

  MONTH_NUMBER: 'M',
  MONTH_NUMBER_WITH_ZERO: 'MM',

  MONTH_NAME: 'MMMM',
  MONTH_DAY: 'MM-DD',
  LITERAL_MONTH_DAY: 'MMMM DD',
  MONTH_YEAR: 'MMMM YYYY',

  DAY_NUMBER: 'D',
  DAY_ZERO_NUMBER: 'DD',

  DAY_NAME: 'dddd',
  DAY_NAME_SHORT: 'dd',

  DAY_NAME_WITH_NUMBER: 'dddd D',
  DAY_NAME_WITH_ZERO_NUMBER: 'dddd DD',
  DAY_NAME_WITH_DATE: 'dddd YYYY-MM-DD',
  DAY_NAME_WITH_TIME: 'dddd HH:mm',

  YEAR: 'YYYY',

  WEEK_YEAR: 'WW GGGG',
  WEEK: 'WW',

  TIME_FULL: 'HH:mm:ss' as TimeString,
  TIME_SHORT: 'HH:mm' as ShortTimeString,

  HOUR: 'HH',
  MINUTE: 'mm',
}

export enum Day {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}
