import { computed, ref } from 'vue'

import { useI18n } from 'vue-i18n'

import { RouteLocationRaw, useRoute } from 'vue-router'

import { lang } from '@/plugins/langjs'

type BreadcrumbItem = RouteLocationRaw & {
  label: string
  inactive: boolean
}

const breadcrumbParam = ref<Record<string, string>>({})

export function useBreadcrumb() {
  const route = useRoute()

  const { t } = useI18n()

  function addBreadcrumbParam(key: string, value: string) {
    breadcrumbParam.value[key] = value
  }

  const breadcrumb = computed((): BreadcrumbItem[] => {
    const parametersRegex = /^:\w*$/

    const isLocallyTranslatedRegex = /^(\w| )*\.(\w| )*$/

    const dynamicBreadcrumb = route.matched
      .filter((r) => 'breadcrumb' in r.meta)
      .map((r) => {
        const match = r.meta.breadcrumb!.match(parametersRegex)
        const isLocallyTranslated = isLocallyTranslatedRegex.test(r.meta.breadcrumb!)
        const translatedLabel = isLocallyTranslated ? t(`${r.meta.breadcrumb!}`) : lang.trans(`_.${r.meta.breadcrumb!}`)

        return {
          name: r.name,
          label: match ? breadcrumbParam.value[match[0]] : translatedLabel,
          inactive: r.meta.breadcrumbInactive ?? false,
        }
      })

    return dynamicBreadcrumb
  })

  return { breadcrumb, addBreadcrumbParam }
}
