import { App } from 'vue'

import Langjs, { Replacements } from 'lang.js'

// const userLocale = localStorage.getItem('lang')
const navigatorLocale = navigator.language.split('-')[0]

// const currentLocale = userLocale ?? navigatorLocale
const currentLocale = navigatorLocale

const lang = new Langjs({
  messages: window.translations,
  locale: ['fr', 'en'].includes(currentLocale) ? currentLocale : 'en', // Current locale
  fallback: 'en', // Fallback language for missing translations
})

const initLang = (app: App) => {
  app.config.globalProperties.trans = (key: string, replacements?: Replacements) => {
    return lang.trans(key, replacements)
  }

  app.config.globalProperties.$trans = (key: string, replacements?: Replacements) => {
    return lang.trans(key, replacements)
  }

  app.config.globalProperties.transChoice = (
    key: string,
    number: number,
    replacements?: Replacements,
    locale?: string,
  ) => {
    return lang.choice(key, number, replacements, locale)
  }
}

export { lang, initLang }
