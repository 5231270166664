import { serialize } from 'object-to-formdata'

import { Dto, Nullable } from '@algorh/shared'

import { SubdivisionWithProject } from '@/api/interfaces/Subdivision'

import {
  CustomerAdvisersForInternalTaskAutomaticDto,
  CustomerAdvisersForManualMeetingDto,
  CustomerAdvisersForManualTrainingDto,
} from '@/api/scheduling'

import { fetchJson, fetchUpload, futch } from '@/utils/fetch'

import { AmbitionOverride, AmbitionWithOverride } from '../interfaces/Ambition'
import { AutomationConfig, AutomationConfigWithDefault } from '../interfaces/Config'
import { CustomerAdviserPreview } from '../interfaces/Employee'
import {
  InternalTaskCustomerAdviser,
  InternalTaskDto,
  InternalTaskInstance,
  InternalTaskType,
  PreValidationInternalTasksDto,
} from '../interfaces/InternalTask'
import { RuleOverride, RuleWithOverride } from '../interfaces/Rule'
import { MassPlanificationPreValidateData, SchedulingPlanning } from '../interfaces/Scheduling'
import { DayToleranceMesh } from '../interfaces/Planning'

import { MassPlanificationCustomerAdvisersDto, MassPlanificationPreValidateDto } from './subdivisions.service.type'

const BASE_URI = '/api/scheduling/operational-subdivisions'

const SchedulingSubdivisionsApiService = {
  getSubdivisions() {
    return fetchJson<SubdivisionWithProject[]>('GET', `${BASE_URI}`)
  },
  getPlanningByDate(subdivisionId: number, date: string) {
    return fetchJson<SchedulingPlanning>('GET', `${BASE_URI}/${subdivisionId}/plannings/${date}`)
  },
  getMassPlanificationPeriodsCustomerAdvisers(
    subdivisionId: number,
    payload: MassPlanificationCustomerAdvisersDto,
    signal: AbortSignal,
  ) {
    return fetchJson<CustomerAdviserPreview[], null, MassPlanificationCustomerAdvisersDto>(
      'GET',
      `${BASE_URI}/${subdivisionId}/mass-planification/plan-periods/customer-advisers`,
      null,
      payload,
      signal,
    )
  },
  getMassPlanificationRecurrenceCustomerAdvisers(
    subdivisionId: number,
    payload: MassPlanificationCustomerAdvisersDto,
    signal: AbortSignal,
  ) {
    return fetchJson<CustomerAdviserPreview[], null, MassPlanificationCustomerAdvisersDto>(
      'GET',
      `${BASE_URI}/${subdivisionId}/mass-planification/plan-recurrence/customer-advisers`,
      null,
      payload,
      signal,
    )
  },
  getMassPlanificationReplaceCustomerAdvisers(
    subdivisionId: number,
    payload: MassPlanificationCustomerAdvisersDto,
    signal: AbortSignal,
  ) {
    return fetchJson<CustomerAdviserPreview[], null, MassPlanificationCustomerAdvisersDto>(
      'GET',
      `${BASE_URI}/${subdivisionId}/mass-planification/replace-periods/customer-advisers`,
      null,
      payload,
      signal,
    )
  },
  getCustomerAdvisersForInternalTaskAutomatic(
    subdivisionId: number,
    payload: Dto<CustomerAdvisersForInternalTaskAutomaticDto>,
    signal: AbortSignal,
  ) {
    return fetchJson<InternalTaskCustomerAdviser[], null, Dto<CustomerAdvisersForInternalTaskAutomaticDto>>(
      'GET',
      `${BASE_URI}/${subdivisionId}/customer-advisers-for-internal-task-automatic`,
      null,
      payload,
      signal,
    )
  },
  getCustomerAdvisersForManualMeeting(
    subdivisionId: number,
    payload: Dto<CustomerAdvisersForManualMeetingDto>,
    signal: AbortSignal,
  ) {
    return fetchJson<InternalTaskCustomerAdviser[], null, Dto<CustomerAdvisersForManualMeetingDto>>(
      'GET',
      `${BASE_URI}/${subdivisionId}/customer-advisers-for-manual-meeting`,
      null,
      payload,
      signal,
    )
  },
  getCustomerAdvisersForManualTraining(
    subdivisionId: number,
    payload: Dto<CustomerAdvisersForManualTrainingDto>,
    signal: AbortSignal,
  ) {
    return fetchJson<InternalTaskCustomerAdviser[], null, Dto<CustomerAdvisersForManualTrainingDto>>(
      'GET',
      `${BASE_URI}/${subdivisionId}/customer-advisers-for-manual-training`,
      null,
      payload,
      signal,
    )
  },
  getInternalTaskInstances(
    subdivisionId: number,
    type: InternalTaskType,
    dto: InternalTaskDto,
  ) {
    return fetchJson<InternalTaskInstance[], null, InternalTaskDto>(
      'GET',
      `${BASE_URI}/${subdivisionId}/internal-task-instances/${type}`,
      null,
      dto,
    )
  },
  getRules(subdivisionId: number) {
    return fetchJson<RuleWithOverride[]>('GET', `${BASE_URI}/${subdivisionId}/rules`)
  },
  getAmbitions(subdivisionId: number) {
    return fetchJson<AmbitionWithOverride[]>('GET', `${BASE_URI}/${subdivisionId}/ambitions`)
  },
  getAutomationConfig(subdivisionId: number) {
    return fetchJson<AutomationConfig>('GET',
      `${BASE_URI}/${subdivisionId}/automation-config`,
    ) as Promise<AutomationConfigWithDefault>
  },
  putAutomationConfig(subdivisionId: number, data: { day: Nullable<string>, time: Nullable<string> }) {
    return fetchJson<AutomationConfig, Dto<{
      scheduler_start_day: string
      scheduler_start_time: string
    }>>('PUT', `${BASE_URI}/${subdivisionId}/scheduler-config`, {
      scheduler_start_day: data.day,
      scheduler_start_time: data.time,
    })
  },
  putSlotToleranceOverride(
    subdivisionId: number,
    values: Nullable<{ min: number, max: number }>,
  ) {
    return fetchJson<AutomationConfig, {
      slot_tolerance: Nullable<{ min: Nullable<number>, max: Nullable<number> }>
    }>('PUT', `${BASE_URI}/${subdivisionId}/slot-tolerance`, {
      slot_tolerance: values,
    })
  },
  putDayToleranceOverride(
    subdivisionId: number,
    values: Nullable<{ min: Nullable<number>, max: Nullable<number>, mesh: Nullable<DayToleranceMesh> }>,
  ) {
    return fetchJson<AutomationConfig, {
      day_tolerance: Nullable<{ min: Nullable<number>, max: Nullable<number>, mesh: Nullable<DayToleranceMesh> }>
    }>('PUT', `${BASE_URI}/${subdivisionId}/day-tolerance`, {
      day_tolerance: values,
    })
  },
  postMassPlanificationPeriodsPreValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson<MassPlanificationPreValidateData, MassPlanificationPreValidateDto>(
      'POST',
      `${BASE_URI}/${subdivisionId}/mass-planification/plan-periods/pre-validate`,
      payload,
    )
  },
  postMassPlanificationRecurrencePreValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson<MassPlanificationPreValidateData, MassPlanificationPreValidateDto>(
      'POST',
      `${BASE_URI}/${subdivisionId}/mass-planification/plan-recurrence/pre-validate`,
      payload,
    )
  },
  postMassPlanificationReplacePreValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson<MassPlanificationPreValidateData, MassPlanificationPreValidateDto>(
      'POST',
      `${BASE_URI}/${subdivisionId}/mass-planification/replace-periods/pre-validate`,
      payload,
    )
  },
  postMassPlanificationPeriodsValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson(
      'POST',
      `${BASE_URI}/${subdivisionId}/mass-planification/plan-periods/validate`,
      payload,
    )
  },
  postMassPlanificationRecurrenceValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson(
      'POST',
      `${BASE_URI}/${subdivisionId}/mass-planification/plan-recurrence/validate`,
      payload,
    )
  },
  postMassPlanificationReplaceValidate(subdivisionId: number, payload: MassPlanificationPreValidateDto) {
    return fetchJson(
      'POST',
      `${BASE_URI}/${subdivisionId}/mass-planification/replace-periods/validate`,
      payload,
    )
  },
  postSubdivisionRuleOverride(
    subdivisionId: number,
    ruleId: number,
    override: Nullable<RuleOverride>,
  ) {
    return fetchJson<RuleOverride, Nullable<RuleOverride>>(
      'POST',
      `${BASE_URI}/${subdivisionId}/rules/${ruleId}/override`,
      override,
    )
  },
  postSubdivisionAmbitionOverride(
    subdivisionId: number,
    ambitionId: number,
    override: Nullable<AmbitionOverride>,
  ) {
    return fetchJson<AmbitionOverride, Nullable<AmbitionOverride>>(
      'POST',
      `${BASE_URI}/${subdivisionId}/ambitions/${ambitionId}/override`,
      override,
    )
  },
  postCustomerAdvisersFile(subdivisionId: number, file: File, callback: (loaded: number) => void) {
    const formData = new FormData()

    formData.append('file', file)

    return futch(
      'POST',
      `${BASE_URI}/${subdivisionId}/customer-advisers-for-internal-task/import`,
      formData,
      callback,
    )
  },
  postPreValidationMeetings(subdivisionId: number, dto: Dto<PreValidationInternalTasksDto>) {
    return fetchUpload<{ id: number }>(
      'POST',
      `${BASE_URI}/${subdivisionId}/meetings`,
      serialize(dto, { booleansAsIntegers: true, indices: true }),
    )
  },
  postPreValidationTrainings(subdivisionId: number, dto: Dto<PreValidationInternalTasksDto>) {
    return fetchUpload<{ id: number }>(
      'POST',
      `${BASE_URI}/${subdivisionId}/trainings`,
      serialize(dto, { booleansAsIntegers: true, indices: true }),
    )
  },
  postTrainingImport(file: File, subdivisionId: number, callback: (loaded: number) => void) {
    const formData = new FormData()
    formData.append('file', file)
    return futch<string>(
      'POST',
      `${BASE_URI}/${subdivisionId}/trainings/import`,
      formData,
      callback,
    )
  },
}

export { SchedulingSubdivisionsApiService }
