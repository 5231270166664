<script lang="ts" setup>
  interface Props {
    readonly errors?: string[]
  }

  const props = defineProps<Props>()
</script>

<template>
  <div
    v-if="errors"
    class="errors"
  >
    <span
      v-for="(error, i) in props.errors"
      :key="`error-${i}`"
      class="error"
    >
      {{ error }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .errors {
    display: flex;
    flex-direction: column;
    margin-top: var(--alg-spacing-xs);

    .error {
      margin: var(--alg-spacing-xs) 0;
      color: var(--alg-color-state-danger);
      font-size: var(--alg-font-size-s);
      font-weight: var(--alg-font-weight-regular);
      hyphens: auto;
    }
  }
</style>
