export const ICON_MATERIAL_NAMES = [
  'add',
  'add-circle',
  'ads-click',
  'arrow-back',
  'auto-fix',
  'autorenew',
  'balance',
  'bar-chart-4-bars',
  'beach-access',
  'block',
  'bolt',
  'bookmark',
  'business-center',
  'cached',
  'calendar-add-on',
  'calendar-today',
  'cancel',
  'category',
  'check',
  'chevron-left',
  'chevron-right',
  'close',
  'content-copy',
  'content-paste',
  'dashboard',
  'data-usage',
  'date-range',
  'delete',
  'design-services',
  'done-all',
  'do-not-disturb-on-fill',
  'do-not-disturb-on',
  'drag-indicator',
  'error-fill',
  'error',
  'event',
  'expand-less',
  'expand-more',
  'filter-alt',
  'grid-on',
  'group',
  'info',
  'laptop-windows',
  'link-off',
  'link',
  'location-away',
  'lock',
  'logout',
  'more-vert',
  'notifications',
  'percent',
  'person-off',
  'person',
  'play-arrow',
  'policy',
  'power-rounded',
  'query-stats',
  'remove',
  'remove-selection',
  'restart-alt',
  'rocket-launch',
  'schedule',
  'search',
  'send',
  'settings',
  'stop',
  'storefront',
  'stylus',
  'update',
  'history',
  'support',
  'table-rows-narrow',
  'task-alt',
  'today',
  'trending-up',
  'tune',
  'undo',
  'upload',
  'visibility-off',
  'visibility',
  'volunteer-activism',
  'warning',
  'checkbox-checked',
  'checkbox-indeterminate',
] as const

export const ICON_ACTIVITY_NAMES = [
  'activity-beach-access',
  'activity-call',
  'activity-chat',
  'activity-group',
  'activity-layers',
  'activity-location-away',
  'activity-mail',
  'activity-phone-in',
  'activity-phone-out',
  'activity-school',
  'activity-user-arrow-down',
  'activity-user-arrow-up',
] as const

export const ICON_REMOTE_NAMES = [
  'remote-interview',
  'remote-present',
  'remote-remote-work',
  'remote-report',
] as const

export const ICON_CUSTOM_NAMES = [
  'avatar',
  'apartment',
  'burger-menu',
  'download-cloud',
  'sort-asc',
  'sort-default',
  'sort-desc',
  'switch-mode',
] as const

export const ICON_NAMES = [
  ...ICON_MATERIAL_NAMES,
  ...ICON_ACTIVITY_NAMES,
  ...ICON_REMOTE_NAMES,
  ...ICON_CUSTOM_NAMES,
] as const

export const ICON_SIZES = ['xs', 's', 'm', 'l', 'xl', 'xxl'] as const
