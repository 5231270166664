import en from './en'
import fr from './fr'
import it from './it'

export type I18nKeys = keyof typeof messages
export type I18nRecord = Record<I18nKeys, unknown>

export const messages = {
  en,
  fr,
  it,
}
