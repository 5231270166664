import { AuthConfig } from '@/interfaces'

import { LoginDto, LoginResponse, ForgotPasswordDto, ResetPasswordDto } from './auth.service.type'

import { fetchJson } from '../utils/fetch'

const BASE_URI = '/api/auth'

const AuthApiService = {
  fetchAuthConfig: () => {
    return fetchJson<AuthConfig>('GET', `${BASE_URI}/config`)
  },
  login: (dto: LoginDto) => {
    return fetchJson<LoginResponse, LoginDto>('POST', `${BASE_URI}/login`, dto)
  },
  logout: () => {
    return fetchJson('POST', `${BASE_URI}/logout`)
  },
  forgotPassword: (dto: ForgotPasswordDto) => {
    return fetchJson('POST', `${BASE_URI}/forgot-password`, dto)
  },
  resetPassword: (dto: ResetPasswordDto) => {
    return fetchJson('POST', `${BASE_URI}/reset-password`, dto)
  },
}

export { AuthApiService }
