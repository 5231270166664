import { RouteRecordRaw, RouterView } from 'vue-router'

import { UserPermissions } from '@/api/interfaces/User'
import AppLayout from '@/layouts/app/App.vue'

const absenceRouter: RouteRecordRaw[] = [
  {
    name: 'absences',
    path: 'absences',
    redirect: { name: 'absences-my-absences' },
    meta: {
      breadcrumb: 'absences.Absences',
      breadcrumbInactive: true,
      layout: AppLayout,
    },
    children: [
      {
        path: 'my-teams-absences',
        name: 'absences-my-teams-absences',
        component: () => import('./pages/my-teams-absences/MyTeamsAbsences.vue'),
        meta: {
          breadcrumb: 'common.Dashboard',
        },
      },
      {
        path: 'leave-request',
        name: 'absences-leave-request',
        component: () => import('./pages/leave-request/LeaveRequest.vue'),
        meta: {
          breadcrumb: 'absences.Request an absence',
        },
      },
      {
        path: 'my-absences',
        meta: {
          redirectable: true,
          weight: 1,
          breadcrumb: 'absences.My absences',
        },
        name: 'absences-my-absences',
        component: () => import('./pages/my-absences/MyAbsences.vue'),
        children: [
          {
            path: ':id',
            name: 'absences-my-absence-details',
            component: () => import('./pages/my-absences/MyAbsenceDetails.vue'),
          },
        ],
      },
      {
        path: 'validations',
        name: 'absences-validations',
        redirect: { name: 'absences-validations-list' },
        meta: {
          breadcrumb: 'absences.Absence validation',
        },
        component: RouterView,
        children: [
          {
            path: '',
            name: 'absences-validations-list',
            component: () => import('./pages/validations/Validations.vue'),
            meta: {
              permissions: [
                UserPermissions.ACCEPT_OR_DENY_PENDING_ABSENCES,
                UserPermissions.ACCEPT_OR_DENY_PENDING_ABSENCES_ON_CRITICAL_PERIODS,
              ],
            },
          },
          {
            path: ':id',
            name: 'absences-validation-details',
            component: () => import('./pages/validations/ValidationDetails.vue'),
            meta: {
              permissions: [UserPermissions.SEE_ABSENCES],
            },
          },
        ],
      },
      {
        path: 'critical-period-absences',
        name: 'absences-critical-period-absences',
        component: () =>
          import('./pages/critical-period-absences/CriticalPeriodAbsences.vue'),
        meta: {
          permissions: [
            UserPermissions.ACCESS_ABSENCES_ON_CRITICAL_PERIODS,
            UserPermissions.ACCEPT_OR_DENY_PENDING_ABSENCES_ON_CRITICAL_PERIODS,
          ],
          breadcrumb: 'absences.Critical period absences',
        },
      },
      {
        path: 'employees',
        name: 'absences-employees',
        redirect: { name: 'absences-employees-list' },
        component: RouterView,
        meta: {
          permissions: [UserPermissions.CREATE_ABSENCES_FOR_OTHER],
          breadcrumb: 'absences.Absence of an employee',
        },
        children: [
          {
            path: '',
            name: 'absences-employees-list',
            component: () => import('./pages/employees/Impersonate.vue'),
          },
        ],
      },
      {
        path: 'validation-processes',
        name: 'absences-validation-processes',
        redirect: { name: 'absences-validation-process-list' },
        component: () =>
          import('./pages/validation-processes/ValidationProcesses.vue'),
        meta: {
          permissions: [UserPermissions.ACCESS_VALIDATION_PROCESSES],
          breadcrumb: 'absences.Validation management',
        },
        children: [
          {
            path: '',
            name: 'absences-validation-process-list',
            component: () =>
              import('./pages/validation-processes/ValidationProcessList.vue'),
          },
          {
            path: ':id',
            name: 'absences-validation-process',
            component: () =>
              import('./pages/validation-processes/ValidationProcessForm.vue'),
            meta: {
              breadcrumb: ':id',
            },
          },
          {
            path: 'create',
            name: 'absences-validation-process-create',
            component: () =>
              import('./pages/validation-processes/ValidationProcessForm.vue'),
          },
        ],
      },
      {
        path: 'import-leave-balances',
        name: 'absences-import-leave-balances',
        component: () => import('./pages/import-leave-balances/ImportLeaveBalances.vue'),
        meta: {
          permissions: [UserPermissions.IMPORT_LEAVE_BALANCES],
          breadcrumb: 'absences.Import leave balances',
        },
      },
    ],
  },
]

export { absenceRouter }
