import {
  UserSchedule,
  InternalTaskInstancePreview,
  DateString,
  ManualScheduling,
  Nullable,
} from '@algorh/shared'

import { Dto } from '@/interfaces'

import { fetchJson } from '@/utils/fetch'

import { TrainingDomain, TrainingType } from '../interfaces/InternalTask'
import { SchedulingConfig } from '../interfaces/Scheduling'

import {
  GetInternalTaskInstancesDto,
  GetScheduleUsersDto,
} from './scheduling.service.type'

const BASE_URI = '/api/scheduling'

const SchedulingApiService = {
  getSchedulingConfig() {
    return fetchJson<SchedulingConfig>('GET', `${BASE_URI}/config`)
  },
  getTrainingTypes() {
    return fetchJson<TrainingType[]>('GET', `${BASE_URI}/training-types`)
  },
  getTrainingDomains() {
    return fetchJson<TrainingDomain[]>('GET', `${BASE_URI}/training-domains`)
  },
  getManualSchedulings(dto: Dto<{ start_date: DateString, end_date: DateString, user_ids: number[] }>) {
    return fetchJson<ManualScheduling[]>(
      'GET',
      `${BASE_URI}/manual-schedulings`,
      null,
      dto,
    )
  },
  getScheduleUsers(dto: GetScheduleUsersDto) {
    return fetchJson<UserSchedule[], null, GetScheduleUsersDto>(
      'GET',
      `${BASE_URI}/users`,
      null,
      dto,
    )
  },
  getInternalTaskInstances(dto: GetInternalTaskInstancesDto) {
    return fetchJson<InternalTaskInstancePreview[], null, GetInternalTaskInstancesDto>(
      'GET',
      `${BASE_URI}/internal-task-instances`,
      null,
      dto,
    )
  },
  putInternalTaskInstanceGlobalDetails(
    id: number,
    payload: { name: string, color: string, code: Nullable<string>, description: Nullable<string> },
  ) {
    return fetchJson('PUT', `${BASE_URI}/internal-tasks/${id}`, payload)
  },
}

export { SchedulingApiService }
