import { Nullable } from '#/types/common'

import { EnFrLabels } from './Common'

type RiskMesh = 'day' | 'week' | 'slot'

enum ActivityCategory {
  ABSENCE = 'absence',
  DAY_OFF = 'day_off',
  LUNCH_BREAK = 'lunch_break',
  NON_PRODUCTION = 'non_production',
  PRODUCTION = 'production',
  UNKNOWN = 'unknown',
}

interface ActivityLite {
  id: number
  name: string
  primary_color: string
  secondary_color: string
  icon: Nullable<string>
  selectable: boolean
  schedulable: boolean
  category: ActivityCategory
}
interface Activity {
  id: number
  name: string
  names: EnFrLabels
  primary_color: string
  secondary_color: string
  icon: Nullable<string>
  activity_family_id: Nullable<number>
  selectable: boolean
  schedulable: boolean
  raw_planifiable: boolean
  manually_planifiable: boolean
  risk_mesh: Nullable<RiskMesh>
  import_code: string
  priority: number
  category: ActivityCategory
  is_worked: boolean
  deletable: boolean
}

interface Schedule {
  id: number
  data: {
    editable: boolean
    datetime: string
    project_activity_id: number
  }[]
  start_date: string
  end_date: string
  start_time: string
  end_time: string
}

export { ActivityCategory }

export type { RiskMesh, Activity, Schedule, ActivityLite }
