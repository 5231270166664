import { fetchJson, futch } from '@/utils'

import type { Dto } from '@/interfaces'

import type {
  Absence,
  AbsenceComment,
  AbsencePreview,
  AbsenceType,
  AbsencesCountByStatus,
  RemoteType,
  Staffing,
  AbsencesConfig,
} from '../types'

import type {
  AbsenceDto,
  AbsencePreValidationDto,
  GetAbsencesDto,
} from './absences.service.type'

const BASE_URI = '/api/gta'

const AbsencesApiService = {
  getAbsencesConfig() {
    return fetchJson<AbsencesConfig>('GET', `${BASE_URI}/config`)
  },
  getAbsences: (args: GetAbsencesDto) => {
    return fetchJson<Absence[], null, GetAbsencesDto>(
      'GET',
      `${BASE_URI}/absences`,
      null,
      args,
    )
  },
  getAbsenceTypes() {
    return fetchJson<AbsenceType[]>('GET', `${BASE_URI}/absence-types`)
  },
  getRemoteTypes() {
    return fetchJson<RemoteType[]>('GET', `${BASE_URI}/remote-types`)
  },
  deleteRemoteDay(remoteDay: number) {
    return fetchJson<Staffing>('DELETE', `${BASE_URI}/remote-days/${remoteDay}`)
  },
  postAbsencePreValidation(dto: Dto<AbsencePreValidationDto>) {
    return fetchJson<{ duration: number, critical_period: boolean }, Dto<AbsencePreValidationDto>>('POST',
      `${BASE_URI}/absences/pre-validation`, dto)
  },
  postAbsence(dto: Dto<AbsenceDto>) {
    return fetchJson<AbsencePreview, Dto<AbsenceDto>>('POST', `${BASE_URI}/absences`, dto)
  },
  getAbsencesCountByStatus() {
    return fetchJson<AbsencesCountByStatus>('GET', `${BASE_URI}/absences/count-by-status`)
  },
  getAbsence: (id: number) => {
    return fetchJson<Absence>('GET', `${BASE_URI}/absences/${id}`)
  },
  postAbsenceComment: (id: number, message: string) => {
    return fetchJson<AbsenceComment, { content: string }>('POST', `${BASE_URI}/absences/${id}/comments`, {
      content: message,
    })
  },
  // Demande d'annulation (approbation du manager)
  cancelAbsence: (id: number) => {
    return fetchJson<AbsencePreview>('POST', `${BASE_URI}/absences/${id}/cancel`)
  },
  // Annule une absence sans approbation
  deleteAbsence: (id: number) => {
    return fetchJson('DELETE', `${BASE_URI}/absences/${id}`)
  },
  deleteOtherAbsences: ({ absences }: { absences: number [] }) => {
    return fetchJson('DELETE', `${BASE_URI}/absences/others`, { absences })
  },
  importLeaveBalances: (file: File, callback: (loaded: number) => void) => {
    const formData = new FormData()
    formData.append('file', file)
    return futch<string>(
      'POST',
      `${BASE_URI}/leave-balances/import`,
      formData,
      callback,
    )
  },
}

export { AbsencesApiService }
