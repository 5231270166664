<script setup lang="ts">
  import { AlgIllustration, AlgModal } from '@algorh/ui'

  import { lang } from '@/plugins/langjs'

  const handleConfirm = () => {
    localStorage.clear()
    window.location.reload()
  }
</script>

<template>
  <AlgModal
    name="app-update"
    size="m"
    :allow-close="false"
    :allow-cancel="false"
    :confirm-button-text="lang.trans('_.Continue')"
    @confirm="handleConfirm"
  >
    <template #title>
      {{ lang.trans('_.New version available') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgIllustration
          name="deploying"
          :size="148"
        />
        <p class="subtitle">
          {{ lang.trans('_.A new version of the application is available') }}
        </p>
        <p>{{ lang.trans('_.Please update to continue using the application') }}</p>
      </div>
    </template>
  </AlgModal>
</template>

<style lang="scss" scoped>
  @import './AlertModal';
</style>
