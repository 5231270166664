import { IconSize } from '#/types'

export const calcIconSize = (iconSize: IconSize) => {
  switch (iconSize) {
    case 'xs':
      return '0.75rem'
    case 's':
      return '1rem'
    case 'm':
      return '1.5rem'
    case 'l':
      return '2rem'
    case 'xl':
      return '2.5rem'
    case 'xxl':
      return '3rem'
  }
}
