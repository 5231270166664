import { storeToRefs } from 'pinia'

import { ref } from 'vue'

import { Nullable } from '@algorh/shared'

import { useAppStore } from '@/appStore'

// TODO: Retirer les surchages quand le type sera corrigé
// https://github.com/microsoft/TypeScript/issues/58674
// https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1725
export enum NotificationPermission {
  GRANTED = 'granted',
  DENIED = 'denied',
  DEFAULT = 'default',
}

type NotificationDirection = 'auto' | 'ltr' | 'rtl'

interface NotificationAction {
  action: string
  title: string
  icon?: string
}

interface NotificationOptions {
  actions?: NotificationAction[]
  badge?: string
  body?: string
  data?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  dir?: NotificationDirection
  icon?: string
  lang?: string
  requireInteraction?: boolean
  silent?: Nullable<boolean>
  tag?: string
}

export function useNotifications() {
  const appStore = useAppStore()

  const { appLogo } = storeToRefs(appStore)

  const permission = ref<NotificationPermission>(NotificationPermission.DEFAULT)

  async function requestNotificationPermission() {
    if (!window.isSecureContext) {
      throw new Error('Notifications can only be requested over HTTPS')
    }

    try {
      await navigator.serviceWorker.register('/notifications.js')
      permission.value = await Notification.requestPermission() as NotificationPermission
    } catch (e) {
      throw new Error('Error while requesting notification permission: ', e as Error)
    }
  }

  async function showNotification(title: string, options?: NotificationOptions) {
    if (permission.value === NotificationPermission.DEFAULT) {
      await requestNotificationPermission()
    }

    if (permission.value === NotificationPermission.DENIED) {
      throw new Error('Notification permission denied')
    }

    const registration = await navigator.serviceWorker.ready

    await registration.showNotification(title, {
      icon: `/assets/svg/brands/${appLogo.value}.svg`,
      ...options,
    })
  }

  async function getNotifications(tag?: string) {
    if (permission.value === NotificationPermission.DEFAULT) {
      await requestNotificationPermission()
    }

    if (permission.value === NotificationPermission.DENIED) {
      throw new Error('Notification permission denied')
    }

    const registration = await navigator.serviceWorker.ready

    const notifications = await registration.getNotifications({ tag })

    return notifications
  }

  return {
    requestNotificationPermission,
    showNotification,
    getNotifications,
  }
}
