/* eslint-disable no-redeclare */

/**
 * Sanitize a string by removing accents and diacritics
 * @param str
 * @returns Sanitized string
 */
function sanitizeString(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Sanitize a search string and return an array of matching options
 * @param search
 * @param options
 * @returns Array of matching options
 */
function sanitizeSearch<T = string>(search: string, options: T[], key: keyof T): T[]
function sanitizeSearch(search: string, options: string[]): string[]
function sanitizeSearch<K extends string>(
  search: string,
  options: (string | Record<K, string>)[],
  key?: K,
) {
  const regex = new RegExp(`${sanitizeString(search)}`, 'gi')
  return options.filter((o) =>
    sanitizeString(typeof o === 'object' ? o[key!] : o).match(regex))
}

export { sanitizeString, sanitizeSearch }
