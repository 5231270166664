import absences from './absences.json'
import account from './account.json'
import actions from './actions.json'
import activities from './activities.json'
import ambitions from './ambitions.json'
import auth from './auth.json'
import calendar from './calendar.json'
import common from './common.json'
import datetime from './datetime.json'
import errors from './errors.json'
import exports from './exports.json'
import hyperPeriods from './hyperPeriods.json'
import internalTasks from './internalTasks.json'
import items from './items.json'
import notifications from './notifications.json'
import projects from './projects.json'
import rolesPermissions from './rolesPermissions.json'
import rules from './rules.json'
import scheduling from './scheduling.json'
import statistics from './statistics.json'
import status from './status.json'
import stockExchange from './stockExchange.json'
import subdivisions from './subdivisions.json'
import support from './support.json'
import tags from './tags.json'
import typography from './typography.json'
import units from './units.json'
import users from './users.json'
import workCycles from './workCycles.json'

export default {
  absences,
  account,
  actions,
  activities,
  ambitions,
  auth,
  calendar,
  common,
  datetime,
  errors,
  exports,
  hyperPeriods,
  internalTasks,
  items,
  notifications,
  projects,
  rolesPermissions,
  rules,
  scheduling,
  statistics,
  status,
  stockExchange,
  subdivisions,
  support,
  tags,
  typography,
  units,
  users,
  workCycles,
}
