import { SubdivisionPreview, SubdivisionsNamesTree } from '@/api/interfaces/Subdivision'

import { fetchJson } from '@/utils/fetch'

import { UserProjectActivity } from './interfaces/Activity'
import { MeUser, UserNotification } from './interfaces/User'

import { PutPasswordDto } from './me.service.type'

const BASE_URI = '/api/me'

const MeApiService = {
  getMe() {
    return fetchJson<MeUser>('GET', BASE_URI)
  },
  putPassword(dto: PutPasswordDto) {
    return fetchJson('PUT', `${BASE_URI}/password`, dto)
  },
  getSubdivisions() {
    return fetchJson<SubdivisionPreview[]>('GET', `${BASE_URI}/operational-subdivisions`)
  },
  getSubdivisionsNamesTree() {
    return fetchJson<SubdivisionsNamesTree[]>('GET', `${BASE_URI}/operational-subdivisions/tree`)
  },
  getScopedSubdivisionsNamesTree(subdivisionId: number) {
    return fetchJson<SubdivisionsNamesTree[]>('GET', `${BASE_URI}/operational-subdivisions/${subdivisionId}/tree`)
  },
  getEffectiveSubordinateSubdivisionsInLineage() {
    return fetchJson<SubdivisionPreview[]>(
      'GET',
      `${BASE_URI}/subordinate-subdivisions/effectives-in-lineage`,
    )
  },
  getProjectActivities() {
    return fetchJson<UserProjectActivity[]>('GET', `${BASE_URI}/project-activities`)
  },
  // Notifications
  getNotifications() {
    return fetchJson<UserNotification[]>('GET', `${BASE_URI}/notifications`)
  },
  putNotificationsAsRead(ids: string[]) {
    return fetchJson('PUT', `${BASE_URI}/notifications/mark-as-read`, {
      notification_ids: ids,
    })
  },
  deleteNotifications(ids: string[]) {
    return fetchJson('DELETE', `${BASE_URI}/notifications`, {
      notification_ids: ids,
    })
  },
}

export { MeApiService }
