<script setup lang="ts">
  import { abbreviationOf } from '@algorh/shared'

  import { AvatarSize, AlgIcon } from '#/components'

  type Props = {
    readonly size?: AvatarSize
    readonly name?: string
  }

  defineOptions({
    name: 'AlgAvatar',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
  })
</script>

<template>
  <div
    class="avatar"
    :class="[`size-${props.size}`]"
  >
    <template v-if="props.name">
      {{ abbreviationOf(props.name || '') }}
    </template>
    <template v-else>
      <AlgIcon
        name="person"
        :size="props.size"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .avatar {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--alg-color-surface-border);
    aspect-ratio: 1 / 1;
    background-color: var(--alg-color-surface-secondary);
    color: var(--alg-color-icon-secondary);

    &.size-xs {
      width: var(--alg-spacing-l);
      height: var(--alg-spacing-l);
      border-radius: var(--alg-spacing-l);
      font-size: var(--alg-font-size-xxs);
      line-height: var(--alg-font-size-xxs);
    }

    &.size-s {
      width: var(--alg-spacing-xl);
      height: var(--alg-spacing-xl);
      border-radius: var(--alg-spacing-xl);
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-font-size-s);
    }

    &.size-m {
      width: var(--alg-spacing-xxl);
      height: var(--alg-spacing-xxl);
      border-radius: var(--alg-spacing-xxl);
      font-size: var(--alg-font-size-l);
      line-height: var(--alg-font-size-l);
    }
  }
</style>
