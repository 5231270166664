import { Ref } from 'vue'

import { useEventListener } from './useEventListener'

export function useClickOutside(
  rootEl: Ref<HTMLElement | undefined | null>,
  callback: (e: Event) => unknown,
  exclude?: Ref<HTMLElement | undefined>[],
  disabled?: Ref<boolean | undefined>,
) {
  if (!window) return
  // The `mousedown` event is better than `click` here because it doesn't bubble up like `click`
  // If you've used `click` here, the callback will be run immediatly.
  useEventListener(
    'mousedown',
    (e: Event) => {
      if (disabled?.value) return

      const clickedEl = e.target as HTMLElement
      // Skip if the root element doesn't exist
      if (!rootEl.value) return
      // Skip if the root element contains the clicked element
      if (rootEl.value?.contains(clickedEl)) return

      // Skip if excluded element contains the clicked element
      if (exclude) {
        for (const el of exclude.map((c) => c.value)) {
          if (el?.contains(clickedEl)) return
        }
      }

      // Otherwise execute the action
      callback(e)
    },
    window,
  )
}
