import { keepPreviousData, QueryClient } from '@tanstack/vue-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      placeholderData: keepPreviousData,
    },
  },
})
export { queryClient }
